<template>
    <div class="discount-wrapper">
        <div class="discount-wrapper__inner">
            <h1 class="mb-32">{{ $t('discounts.title') }}</h1>
            <DiscountCodesSetting class="mb-30" />
            <DiscountsSettings class="mb-30" />
            <DiscountPointsSetting />
            <ReferralSetting />
        </div>
    </div>
</template>

<script>
    import DiscountCodesSetting from '@/components/Admin/Discounts/DiscountCodesSetting.vue';
    import DiscountPointsSetting from '@/components/Admin/Discounts/DiscountPointsSetting.vue';
    import DiscountsSettings from '@/components/Admin/Discounts/DiscountsSettings.vue';
    import ReferralSetting from '@/components/Admin/Discounts/ReferralSetting.vue';

    export default {
        name: 'DiscountsPage',
        components: { DiscountCodesSetting, DiscountPointsSetting, DiscountsSettings, ReferralSetting },
    };
</script>

<style scoped lang="scss">
    .discount-wrapper {
        @include column-align-start-justify-start;
        flex-wrap: wrap;

        padding: 15px 0 25px;

        .discount-wrapper__inner {
            max-width: 633px;

            h1 {
                display: none;

                @include media($lg) {
                    display: block;
                }
            }
        }
    }
</style>
