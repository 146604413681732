<template>
    <div class="discounts-settings__wrapper">
        <h2 class="mb-10">{{ $t('discounts.createDiscounts') }}</h2>
        <div class="tag__wrapper">
            <CRTag
                class="tag"
                v-for="discount in discounts"
                :key="discount.id"
                :delete-allowed="true"
                type="additional"
                @delete="deleteDiscount(discount)"
                svg-color="#00595A"
            >
                {{ discount.amount }}{{ getSign(discount.type) }}
            </CRTag>
        </div>

        <div class="type-buttons">
            <CRButton
                :active="button.type === form.type"
                size="x-small"
                v-for="button in typeButtons"
                :key="button.id"
                pattern="section"
                @click="form.type = button.type"
            >
                {{ button.name }}
            </CRButton>
        </div>

        <CRInput
            :label="label"
            :placeholder="label"
            type="number"
            v-model="form.amount"
            :errors="errors?.amount"
            @onChange="$filters.clearError(errors, 'amount')"
        />

        <CRButton class="button" @click="createDiscount">
            {{ $t('buttons.add') }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRTag from '@/components/Common/Tags/CRTag';
    import { DiscountApi } from '@/api';
    import { getSign } from '@/helpers/SignHelper';
    import { typeButtons } from '@/config/discounts/discount';
    import { DiscountTypeEnum } from '@/utils/enums';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'DiscountsSettings',
        components: { CRTag, CRInput, CRButton },
        data() {
            return {
                typeButtons,
                getSign,
                discounts: [],
                form: {
                    type: DiscountTypeEnum.PERCENTAGE,
                    amount: '',
                },
                errors: {},
            };
        },
        computed: {
            label() {
                return this.form.type === DiscountTypeEnum.PERCENTAGE
                    ? this.$t('discounts.amount')
                    : this.$t('discounts.amountFixed');
            },
        },
        methods: {
            async createDiscount() {
                try {
                    const response = await DiscountApi.store(this.form);
                    this.discounts.push(response.data);

                    this.$filters.toast(this.$t('discounts.discountCreated'), { type: TYPE.SUCCESS });

                    this.form.amount = '';
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            async fetchDiscounts() {
                try {
                    const response = await DiscountApi.getAll();
                    this.discounts = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async deleteDiscount(discount) {
                try {
                    await DiscountApi.destroy(discount.id);
                    this.discounts = this.discounts.filter((term) => term.id !== discount.id);

                    this.$filters.toast(this.$t('discounts.discountDeleted'), { type: TYPE.SUCCESS });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.fetchDiscounts();
        },
    };
</script>

<style scoped lang="scss">
    .discounts-settings__wrapper {
        .tag__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            margin: 20px 0;

            .tag {
                margin: 0 8px 8px 0;
            }
        }

        .type-buttons {
            @include row-align-center;

            * {
                margin: 0 8px 20px 0;
            }
        }

        .button {
            margin-top: 30px;
        }
    }
</style>
