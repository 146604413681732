import { translation } from '@/helpers/TranslationHelper';
import { DiscountTypeEnum } from '@/utils/enums';

export const typeButtons = [
    {
        type: DiscountTypeEnum.PERCENTAGE,
        name: translation('discounts.percentage'),
    },
    {
        type: DiscountTypeEnum.FIXED,
        name: translation('discounts.fixed'),
    },
];

export const discountCategories = [
    {
        id: 1,
        name: 'Asmeniniai',
    },
    {
        id: 2,
        name: 'Partneriai',
    },
    {
        id: 3,
        name: 'Dovanų kuponai',
    },
    {
        id: 4,
        name: 'Kiti',
    },
];