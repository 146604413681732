<template>
    <div class="discount-wrapper" v-if="referralSetting">
        <h2 class="mb-20">{{ $t('discounts.referralSettings') }}</h2>
        <CRInput
            class="mb-25 mt-20"
            v-model="referralSetting.amountForReferralUser"
            :label="$t('discounts.referralDiscountPoints')"
            :placeholder="$t('placeholder.enterReferralDiscountPoints')"
            :errors="errors?.amountForReferralUser"
            :help-text="$t('discounts.howManyPoints')"
            required
            @onChange="$filters.clearError(errors, 'amountForReferralUser')"
        />
        <CRInput
            type="number"
            class="mb-30"
            v-model="referralSetting.amountForReceiverUser"
            :label="$t('discounts.referralReceiverDiscountPoints')"
            :placeholder="$t('placeholder.enterReferralReceiverDiscountPoints')"
            :errors="errors?.amountForReceiverUser"
            :help-text="$t('discounts.discountPointsPersonWhoUsesLink')"
            required
            @onChange="$filters.clearError(errors, 'amountForReceiverUser')"
        />
        <CRToggle class="mb-30" v-model:checked="referralSetting.isDisable">
            {{ $t('discounts.referralFunctionDisable') }}
        </CRToggle>
        <CRButton :disabled="buttonDisabled" @click="update">
            {{ $t('buttons.save') }}
        </CRButton>
    </div>
</template>

<script>
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { ReferralSettingApi } from '@/api';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'ReferralSetting',
        components: { CRButton, CRInput, CRToggle },
        data() {
            return {
                referralSetting: null,

                errors: {},
            };
        },
        computed: {
            buttonDisabled() {
                return !this.referralSetting.amountForReferralUser || !this.referralSetting.amountForReceiverUser;
            },
        },
        methods: {
            async fetchReferralSetting() {
                try {
                    const response = await ReferralSettingApi.get();
                    this.referralSetting = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async update() {
                try {
                    const response = await ReferralSettingApi.update(this.referralSetting.id, this.referralSetting);
                    this.referralSetting = response.data;

                    this.$filters.toast(this.$t('discounts.referralSettings') + this.$t('otherSettings.updated'), {
                        type: TYPE.SUCCESS,
                    });
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
        async mounted() {
            await this.fetchReferralSetting();
        },
    };
</script>

<style scoped lang="scss">
    .discount-wrapper {
        @include column-align-start-justify-start;

        .help-text {
            font-style: italic;
            margin-bottom: 15px;
        }

        .discounts-container {
            @include row-align-start;
            flex-wrap: wrap;

            .tag {
                margin: 5px 8px 8px 0;
            }
        }
    }
</style>
