<template>
    <div class="discount-wrapper" v-if="discountPointSetting">
        <h2 class="mb-10">{{ $t('discounts.pointSettings') }}</h2>
        <p class="help-text">
            {{ $t('discounts.discountPointsExpireAfter', { days: discountPointSetting.expirationDaysCount }) }}
        </p>
        <CRInput
            class="mb-25 mt-20"
            v-model="discountPointSetting.amountToSpend"
            :label="$t('discounts.discountPointValue')"
            :placeholder="$t('discounts.discountPointValue')"
            :errors="errors?.amountToSpend"
            :help-text="$t('discounts.howMuchValueDoesPointHold')"
            required
            @onChange="$filters.clearError(errors, 'amountToSpend')"
        />
        <CRInput
            class="mb-25"
            v-model="discountPointSetting.amountToEarn"
            :label="$t('discounts.purchaseDiscountPoints')"
            :placeholder="$t('placeholder.enterPurchaseDiscountPoints')"
            :errors="errors?.amountToEarn"
            :help-text="$t('discounts.pointsBuyerEarnsPerEur')"
            required
            @onChange="$filters.clearError(errors, 'amountToEarn')"
        />
        <CRInput
            type="number"
            class="mb-30"
            v-model="discountPointSetting.percentage"
            :label="$t('discounts.maxDiscountPointUsage')"
            :placeholder="$t('placeholder.enterMaxDiscountPointUsage')"
            :errors="errors?.percentage"
            :help-text="$t('discounts.maximumAmountOfOrdersValue')"
            required
            @onChange="$filters.clearError(errors, 'percentage')"
        />
        <CRToggle class="mb-20" v-model:checked="discountPointSetting.isCollectingDisabled">
            {{ $t('discounts.disableDiscountPointCollecting') }}
        </CRToggle>
        <CRToggle class="mb-30" v-model:checked="discountPointSetting.isHidden">
            {{ $t('discounts.disableDiscountPointUsage') }}
        </CRToggle>
        <CRButton :disabled="buttonDisabled" @click="update">
            {{ $t('buttons.save') }}
        </CRButton>
    </div>
</template>

<script>
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { DiscountPointSettingApi } from '@/api';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'DiscountPointsSetting',
        components: { CRButton, CRInput, CRToggle },
        data() {
            return {
                discountPointSetting: null,

                errors: {},
            };
        },
        computed: {
            buttonDisabled() {
                return (
                    !this.discountPointSetting.amountToEarn ||
                    !this.discountPointSetting.amountToSpend ||
                    !this.discountPointSetting.percentage
                );
            },
        },
        methods: {
            async fetchDiscountPointSetting() {
                try {
                    const response = await DiscountPointSettingApi.get();
                    this.discountPointSetting = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async update() {
                try {
                    const response = await DiscountPointSettingApi.update(
                        this.discountPointSetting.id,
                        this.discountPointSetting
                    );

                    this.discountPointSetting = response.data;

                    this.$filters.toast(this.$t('discounts.discountPointSettings') + this.$t('otherSettings.updated'), {
                        type: TYPE.SUCCESS,
                    });
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
        async mounted() {
            await this.fetchDiscountPointSetting();
        },
    };
</script>

<style scoped lang="scss">
    .discount-wrapper {
        @include column-align-start-justify-start;

        .help-text {
            font-style: italic;
            margin-bottom: 15px;
        }

        .discounts-container {
            @include row-align-start;
            flex-wrap: wrap;

            .tag {
                margin: 5px 8px 8px 0;
            }
        }
    }
</style>
